<template>
  <div class="about pa-5">
    <v-card>
        <v-toolbar
      color="primary"
      dark
      flat
    >
    <v-img
    class="mx-2"
    src="../assets/socialgeniuslogo.png"
    max-height="40"
    max-width="40"
    contain
    
  ></v-img>
<v-toolbar-title >Welcome to SocialGenius.io!</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-avatar size="48">
      <img src="https://yt3.ggpht.com/O3k22rMrvX6e9nb3b5QJw-NFhkznVMqEoUyyPETS1bvNDRxWSoCwORGX7c_-P4sYa6E3l05Ohw=s240-c-k-c0x00ffffff-no-rj" alt="hackmakemod">
    </v-avatar>
</v-toolbar>
        <v-card-text>
    
    <p class="ma-3">
      We are working hard with our partners to come up with new and interesting projects for the "DIY-er" and "Tinkerer" that use social media, US Census, Weather, Aviation and many other 
      interesting data points. Our goal is to expose them through an easy to use API. We will continue to find new and exciting projects that utilize mainstream micro controllers and micro computers like Arduino based devices and Unix driven micro-computers like the Raspberry Pi.
    </p>
    <p class="ma-3">Check back often for new and interesting projects from Social Genius and our partners!</p>
    <p class="ma-3">Follow us on Twitter <a class="primary--text"
              style="text-decoration: none" href="https://twitter.com/socialgeniusapi">@socialgeniusapi</a></p>
        </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  name:'About'
}
</script>
